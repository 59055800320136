import { useState, useEffect } from "react";
import "./App.css";
import Header from "./components/Header";

import bgimg from "./assets/sean-oulashin-KMn4VEeEPR8-unsplash.jpg";

import topicOfTheMonthImage from "./assets/1738845310011.jpeg";

import { IconButton } from "@material-tailwind/react";
import Loading from "./components/Loading";
import Fab from "./components/Fab";
import CompanyResources from "./components/CompanyResources";
import TopicOfTheMonth from "./components/TopicOfTheMonth";
import CruiseSchedule from "./components/CruiseSchedule";
import Announcements from "./components/Announcements";

import companyResourcesData from "./data/companyResourcesData";

function App() {
  const [slideData, setSlideData] = useState([]);
  const [bgLink, setBgLink] = useState(bgimg);
  const [loading, setLoading] = useState(true);
  const [isMax, setIsMax] = useState(false);
  const [maxedItem, setMaxedItem] = useState();
  const [theme, setTheme] = useState("Light");

  let color = theme === "Light" ? "text-gray-900" : "text-white";

  const debug = process.env.REACT_APP_IS_DEBUG === "true";

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          (debug ? (process.env.REACT_APP_BASE_URL || "http://localhost:5001") : "") + "/fetchdata"
        );
        const result = await response.json();
        setSlideData(result);
      } catch (error) {
        debug && console.error("Error fetching data: ", error);
      }
    };

    const fetchBgData = async () => {
      try {
        const response = await fetch(
          (debug ? (process.env.REACT_APP_BASE_URL || "http://localhost:5001") : "") + "/fetchbgdata"
        );
        const result = await response.json();
        debug && console.log(result);
        setBgLink(result[0].Image);
        setTheme(result[0].Theme);
      } catch (error) {
        debug && console.error("Error fetching data: ", error);
      }
    };

    fetchData();
    fetchBgData();
    // Promise.all([fetchData(), fetchBgData()]).then(() => setLoading(false));

    const timer = setTimeout(() => {
      setLoading(false);
    }, 3000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const img = new Image();
    img.src = bgLink;
    img.onload = () => setBgLink(bgLink);
    img.onerror = () => setBgLink(bgimg);
  }, [bgLink]);

  let topicOfTheMonth = {
    title: "Change Management",
    dueDate: "Due February 27, 2025",
    link: "https://www.linkedin.com/learning/paths/cruise-saudi-change-management-25301153?u=189763761",
    lengthTime: "32m 44s",
    lengthVideos: "1",
    recommendationDate: "February 6, 2025",
    image: topicOfTheMonthImage,
  };

  function handleMaximize(index) {
    setMaxedItem(slideData.slice().reverse()[index].Link.Url);
    setIsMax(true);
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  return (
    <div
      className="App bg-scroll bg-auto bg-no-repeat pb-3"
      style={{
        backgroundColor: "#0A1E46",
        backgroundImage: `url(${bgLink})`,
        backgroundSize: "100% 100vh",
        height: "100vh",
        overflow: loading ? "hidden" : "auto",
      }}
    >
      {loading ? <Loading /> : <></>}
      {isMax ? (
        <div className="absolute w-full h-screen" style={{ zIndex: "100" }}>
          <iframe
            src={maxedItem}
            scrolling="no"
            allowFullScreen
            frameBorder="0"
            style={{ height: "100vh", width: "100%" }}
            className="h-auto w-auto object-contain m-auto"
            sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
          ></iframe>
          <IconButton
            variant="text"
            color="black"
            size="lg"
            onClick={() => {
              setIsMax(false);
              window.scrollTo({
                top: document.body.scrollHeight,
                behavior: "smooth",
              });
            }}
            className="!absolute bottom-0 !right-4 -translate-y-2/4 bg-[#051d49] rounded-full"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="white"
              className="w-6 h-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M9 9V4.5M9 9H4.5M9 9 3.75 3.75M9 15v4.5M9 15H4.5M9 15l-5.25 5.25M15 9h4.5M15 9V4.5M15 9l5.25-5.25M15 15h4.5M15 15v4.5m0-4.5 5.25 5.25"
              />
            </svg>
          </IconButton>
        </div>
      ) : (
        <>
          {/* <Fab /> */}
          <Header theme={theme} />
          <CompanyResources
            companyResources={companyResourcesData}
            color={color}
          />
          <TopicOfTheMonth topicOfTheMonth={topicOfTheMonth} color={color} />
          {/* <CruiseSchedule color={color} />
          <Announcements
            slideData={slideData}
            handleMaximize={handleMaximize}
            color={color}
          /> */}
        </>
      )}
    </div>
  );
}

export default App;
