import React, { useState } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
} from "@material-tailwind/react";

function CustomCard({ icon, title, link, isUnique, isIFrame, disclaimer }) {
  const [isOpen, setIsOpen] = useState(false);
  const [showDisclaimer, setShowDisclaimer] = useState(false);

  const handleOpen = () => {
    setIsOpen(!isOpen);
  };

  const handleDisclaimerOpen = () => {
    setShowDisclaimer(true);
  };

  const handleDisclaimerClose = () => {
    setShowDisclaimer(false);
    if (isIFrame) {
      setIsOpen(true);
    } else {
      window.open(link, "_blank");
    }
  };

  const handleClick = () => {
    if (disclaimer) {
      handleDisclaimerOpen();
    } else if (isIFrame) {
      setIsOpen(true);
    } else {
      window.open(link, "_blank");
    }
  };

  return (
    <>
      {isIFrame && (
        <Dialog size="xl" open={isOpen} handler={handleOpen}>
          <DialogBody>
            <iframe
              width={window.innerWidth}
              height={window.innerHeight - 100}
              src={link}
              frameborder="0"
              marginwidth="0"
              marginheight="0"
              style={{
                border: "none",
                maxWidth: "100%",
                maxHeight: "100vh",
                backgroundColor: "rgb(218 218 218)",
              }}
              allowfullscreen
              webkitallowfullscreen
              mozallowfullscreen
              msallowfullscreen
            ></iframe>
          </DialogBody>
        </Dialog>
      )}
      {disclaimer && (
        <Dialog open={showDisclaimer} handler={handleDisclaimerOpen}>
          <DialogHeader className="flex justify-center">Disclaimer</DialogHeader>
          <DialogBody>{disclaimer()}</DialogBody>
          <DialogFooter className="flex justify-center">
            <Button onClick={handleDisclaimerClose} color="blue">
              Proceed
            </Button>
          </DialogFooter>
        </Dialog>
      )}
      <div onClick={handleClick}>
        <Card
          className="mt-0 items-center cursor-pointer"
          style={{
            width: "13rem",
            height: "10rem",
            backgroundColor: "rgba(255,255,255,0.7)",
            border: isUnique ? "2px solid #111827" : "none",
          }}
        >
          {isUnique && (
            <div
              style={{ borderColor: "#111827" }}
              className="absolute inline-flex items-center justify-center w-4 h-4 text-xs font-bold text-white bg-red-500 border-2 rounded-full -top-2 -end-2 dark:border-gray-900"
            ></div>
          )}
          <CardBody className="flex justify-center items-center flex-col">
            <img
              src={icon}
              alt="card-image"
              style={{ height: "60px", width: "60px" }}
            />
            <Typography
              variant="paragraph"
              color="blue-gray"
              className="mt-3 font-bold"
            >
              {title}
            </Typography>
          </CardBody>
        </Card>
      </div>
    </>
  );
}

export default CustomCard;
